import React, { useCallback } from 'react';
import { hideModal } from 'shared/foreground/actions/modals';
import { globalState } from 'shared/foreground/models';
import { setSendToKindleDocId } from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { createToast } from 'shared/foreground/toasts.platform';
import { sendToKindle } from 'shared/foreground/utils/kindle';

import Button from '../Button';
import Modal from './Modal';
import styles from './ResendToKindleConfirmModal.module.css';

const resendToKindleModalId = 'resend-to-kindle-modal';

export default function ResendToKindleConfirmModal() {
  const docId = globalState(useCallback((state) => state.sendToKindleDocId, []));

  const hide = useCallback(() => {
    setSendToKindleDocId(null);
    hideModal({ id: resendToKindleModalId }, { userInteraction: 'click' });
  }, []);

  const handleConfirm = useCallback(async () => {
    if (docId) {
      await sendToKindle(docId, true);
    } else {
      createToast({
        content: 'Something went wrong, please retry.',
        category: 'error',
      });
    }
    hide();
  }, [docId, hide]);

  const mainContent =
    <div className={styles.contentWrapper}>
      <div className={styles.text}>
        <p className={styles.subtitle}>
          This document has already been sent to your Kindle. Would you like to send it again?
        </p>
      </div>
    </div>;

  const footerContent =
    <div className={styles.buttons}>
      <Button className={styles.cancelButton} onClick={hide} variant="secondary">
        Cancel
      </Button>
      <Button
        onClick={handleConfirm}
        variant="primary"
        autoFocus
      >
        <span className={styles.buttonText}>Send Again</span>
      </Button>
    </div>;

  return (
    <Modal
      id={resendToKindleModalId}
      footerContent={footerContent}
      mainContent={mainContent}
      title="Resend to Kindle"
    />
  );
}
