import React from 'react';

import AddFolderModal from './AddRssFolderModal';
import AddWisereadsItemsModal from './AddWisereadsItemsModal';
import AppStoreQRsModal from './AppStoreQRsModal';
import ExportTemplateModal from './ExportTemplateModal';
import LibraryLocationsModal from './LibraryLocationsModal';
import ResendToKindleConfirmModal from './ResendToKindleConfirmModal';

export default function ModalsContainer() {
  return (
    <>
      <LibraryLocationsModal />
      <AppStoreQRsModal />
      <ExportTemplateModal />
      <AddFolderModal />
      <AddWisereadsItemsModal />
      <ResendToKindleConfirmModal />
    </>
  );
}
